@import '../../App.scss';

.footer {
    background-color: $chs-blue;
    font-family: 'Source Sans Pro',sans-serif;
    margin-top: 122px;

    @include sm {
        margin-top: 0;
    }
    
    &__container {
        margin: 0 120px;
    }

    &__sections {
        display: flex;
        align-items: flex-start;
        padding: 75px 0;
        color: #ffffff;

        @include sm {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        

        &-item {

            @include sm {
                margin-right: 0;
                flex: 0 0 100%;
                width: 100%;
                margin-bottom: 20px;
            }

            h3 {
                margin-bottom: 15px;
                font-size: 16px;
                letter-spacing: 5px;  
                color: #ffffff;
                text-transform: uppercase;
                font-weight: 600;
            } 

            .white-link {
                color: #ffffff;
                font-weight: 400;
            }

            &--info {
                flex: 0 1 30%;

                .logo {
                    display: block;
                    margin-bottom: 24px;
                }

                @include sm {
                    margin-bottom: 40px;
                    border-bottom: 1px solid rgba(255,255,255,.3);
                    padding-bottom: 40px;
                }

                p {
                    color: #ffffff;
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 24px;
                    max-width: 240px;
                }

                span {
                    color: #ffffff;
                    font-weight: normal;
                    display: block;
                }

                a {
                    color: #dbecf8;
                }
            }

            &--company {
                flex: 1 1 50%;

                nav {
                    margin-bottom: 50px;

                    ul {
                        margin: 0;
                        list-style: none;

                        > li {
                            margin: 10px 0;

                            a {
                                color: #ffffff;
                                text-decoration: none;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }

                p {
                    color: #ffffff;
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 24px;
                }
            }

            &--health {
                nav {
                    ul {
                        list-style: none;
                        margin: 0;

                        li {
                            margin-bottom: 15px;
                        }
                    }
                }
            }

            &--social {
                flex: 0 1 auto;

                nav {
                    ul {
                        display: flex;
                        list-style: none;

                        li {
                            margin: 0 10px;

                            a {
                                cursor: pointer;
                            }

                             svg {
                                width:1.5em !important;
                                height: 1.5em !important;
                                color: #ffffff;

                                &:hover {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}