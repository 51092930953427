@import './mixins';

.btn {
    background-color: transparent;
    border: 1px solid $chs-gray;
    padding: 13px 40px;
    box-shadow: none;
    border-radius: 5px;
    color: $chs-gray;
    font-size: 1em;
    transition: all 0.2s ease-in-out;
    outline: none;
    cursor: pointer;
    white-space: nowrap;


    &-full {
        width: 100%;
    }

    &-xs {
        padding: 10px 20px;
    }

    &-lg {
        padding: 15px 60px;
    }

    &:hover {
        background: $chs-white;
        color: $chs-blue;
    }
}

.btn-ghost {
    @extend .btn;

    color: $chs-graystrong;

    &:hover {
        background-color: $chs-blue;
        color: $chs-white; 
        border: 1px solid $chs-blue;
    }
}

.btn-ghostinverse {
    @extend .btn;

    border: 1px solid $chs-blue;
    color: $chs-blue;
}

.btn-primary {
    @extend .btn;

    background-color: $chs-blue;
    border: 1px solid transparent;
    color: $chs-white; 
    font-weight: 600;

    &.inactive {
        background-color: $chs-gray;
    }

    &:hover {
        background-color:  $chs-blue;
        color: $chs-white;
    }
}

.btn-primarydashboard {
    @extend .btn;

    background-color: $chs-patientblue;
    border: 1px solid transparent;
    color: $chs-white; 
    font-weight: 600;

    &.inactive {
        background-color: $chs-gray;
    }

    &:hover {
        background-color:  $chs-patientblue;
        color: $chs-white;
    }
}

.btn-terciary {
    @extend .btn;

    background: rgba(51, 123, 246, 0.1);
    border: none;
    outline: none;
    color: $chs-patientblue;

    &:hover {
        background: rgba(51, 123, 246, 0.1);
        color: $chs-patientblue;
    }
}

.btn-link {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;

    &:hover {
        text-decoration: underline; 
    }

    &--blue {
        color: $chs-blue;
    }
}

.btn-dark {
    background: #021e34;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    line-height: 24px;
    border: none;
    border-radius: 0;

    &:hover {
        transform: translate3d(0px, -3px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
}

.btn-gray {
    background-color: $chs-graymedium;
    color: $chs-white;
}

.btn-outline {
    border-color: $chs-patientblue;
    color: $chs-patientblue;

    &:hover {
        color: $chs-white;
        background: $chs-patientblue;
    }
}