$chs-white: #ffffff;

$chs-black: #222;
$chs-blue: #021E34;
$chs-bluelight: #dbecf8;
$chs-disabledBlue: #BCC3C8;

$chs-bluegray: #36414F;

$chs-secondary: #0B69B1;

$chs-patientblue: #337BF6;

$chs-graystrong: #76838f;
$chs-graystronghover: #424D5A;
$chs-graymedium: #808F99;

$chs-unfocused: #9D9D9D;

$chs-green: #2DAD2A;

$chs-graydisabled: #BCBCBC;

$chs-gray: #DDDBDA;

$chs-accent: #ECBE49;
$chs-accentpastel: #FCE29F;

$chs-border: #E0E0E0;

$chs-success: #58BF84;

$chs-error: #E6492D;
$chs-errorfill: #F8E8E5;

$chs-warning: rgb(246, 196, 48);

$webflow-main: #385483;
$webflow-main-light: #38548340;
$webflow-main-medium: #38548399;
$webflow-paragraph-dark: #333;
$webflow-accent: #e0cba6;
$webflow-accent-light: #ece2d0;
$webflow-main-font: #160042;
$webflow-font-dark: #021e34;
$webflow-secondary-blue: #337bf9;
$webflow-alice-blue: #f5f9fd;